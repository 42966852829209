import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { toNumber } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../constants/utils';
import { FONT_STYLE, THEME_COLORS } from '../../variables';
import Modal, { ModalActions, ModalContent, ModalTitle } from '../Modal';
import QuantitySelector from '../Quantity/QuantitySelector';

const DiscountSelectorModal = ({
  product,
  discountModalIsOpen,
  setDiscountModalIsOpen,
  updateCart,
  cartItems
}) => {
  console.log('Produtos recebidos no modal -> ', product)
  const { t } = useTranslation();
  const {
    onSale,
    onSaleBox,
    discount: productDiscount,
  } = product;
  const [discount, setDiscount] = useState(productDiscount ?? 0);

  const productOnSale = onSale || onSaleBox;
  const productPrice = product.price;
  const productSalePrice = product.salePrice;
  const priceWithDiscount = parseFloat(
    Math.fround(productPrice - (productPrice * discount) / 100).toFixed(2)
  );

  const updateCartItemDiscount = (product, discount, currentCart) => {
    const updatedVariants = product.variants.map((variant) => ({
      ...variant,
      discount: discount,
      discountPrice: discount !== 0
        ? parseFloat(
          Math.fround(variant.price - (variant.price * discount) / 100).toFixed(2)
        )
        : 0,
    }));
    const updatedProducts = [
      {
        ...product,
        discount: discount,
        discountPrice: discount !== 0
          ? parseFloat(
            Math.fround(product.price - (product.price * discount) / 100).toFixed(2)
          )
          : 0,
      },
      ...updatedVariants,
    ];
    // Preserva os produtos que não pertencem ao produto atual
    const filteredCart = currentCart.filter(
      (cartItem) => cartItem.parentId !== product.parentId
    );

    // Atualiza o carrinho com os produtos existentes e os atualizados
    const newCart = [...filteredCart, ...updatedProducts];

    updateCart(newCart);

    updateCart(newCart);
  };

  const decrement = () =>
    discount >= 0 && setDiscount((prevState) => (prevState -= 1));

  const increment = () =>
    discount <= 100 && setDiscount((prevState) => (prevState += 1));

  const openFieldChange = (e) => {
    //  IF number of caracteres is greater than 3 caracteres » return
    if (e.length > 3) return;
    setDiscount(toNumber(e));
  };

  return (
    <Modal
      isOpened={discountModalIsOpen}
      setIsOpened={() => setDiscountModalIsOpen(false)}
      muiComponentNativeProps={{
        PaperProps: {
          sx: {
            maxWidth: '500px'
          }
        }
      }}
    >
      <ModalTitle>{t('cart.applySellerDiscount')}</ModalTitle>
      <ModalContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('tables.price')}</TableCell>
                <TableCell>{t('tables.priceWithDiscount')}</TableCell>
                <TableCell>{t('tables.discountPercentage')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: 1
                    }}
                  >
                    <Typography
                      sx={{
                        textDecoration: productOnSale && 'line-through'
                      }}
                    >
                      {formatCurrency(productPrice)}
                    </Typography>

                    {productOnSale && (
                      <>
                        <Typography
                          sx={{
                            fontWeight: FONT_STYLE.bold,
                            color: THEME_COLORS.primary
                          }}
                        >
                          {formatCurrency(productSalePrice)}
                        </Typography>
                      </>
                    )}
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      fontWeight: FONT_STYLE.semibold,
                      color: THEME_COLORS.red
                    }}
                  >
                    {formatCurrency(priceWithDiscount)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <QuantitySelector
                    quantity={discount}
                    increment={increment}
                    decrement={decrement}
                    incrementDisabled={discount >= 100}
                    decrementDisabled={discount <= 0}
                    openFieldChange={openFieldChange}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </ModalContent>
      <ModalActions>
        <Button
          title={t('cart.applySellerDiscount')}
          variant='contained'
          onClick={() => {
            setDiscountModalIsOpen(false);
            updateCartItemDiscount(product, discount, cartItems);
          }}
        >
          {t('cart.applySellerDiscount')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default DiscountSelectorModal;
