import React from 'react';
import QuantitySelectorOrder from './QuantitySelectorOrder';

const QuantityOrder = ({
                         product,
                         _openFieldDisabled = true,
                         variant = 'default',
                         label,
                         isLoading
                       }) => {
  return (
    <QuantitySelectorOrder
      quantity={product.quantity.unit}
      variant={variant}
      label={label}
      isLoading={isLoading}
    />
  );
};

export default React.memo(QuantityOrder);
