import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GrDocumentPdf } from 'react-icons/gr';
import { formatCurrency } from '../../../constants/utils';
import { IconButton } from '../../../styles';
import { LoadingButton } from '@mui/lab';

const ReceiptsTable = ({ receipts, getInvoice, isLoading }) => {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper} sx={{ marginY: 2 }}>
      <Table size='small' id='receipts-table'>
        <TableHead>
          <TableRow>
            <TableCell>{t('tables.number')}</TableCell>
            <TableCell>{t('tables.document')}</TableCell>
            <TableCell>{t('tables.date')}</TableCell>
            <TableCell>{t('tables.status')}</TableCell>
            <TableCell>{t('tables.dueDate')}</TableCell>
            <TableCell>{t('tables.total')}</TableCell>
            <TableCell>{t('tables.unpaid')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {receipts.map(
            ({
              erpId,
              reference,
              date,
              docType,
              docTypeErp,
              dueDate,
              status,
              value,
              balance
            }) => {
              const docDate = date;
              const docDueDate = dueDate;
              return (
                <TableRow key={`receiptsTable-${reference}`}>
                  <TableCell>{reference}</TableCell>
                  <TableCell>{docType}</TableCell>
                  <TableCell>{docDate}</TableCell>
                  <TableCell>{status}</TableCell>
                  <TableCell>{docDueDate}</TableCell>
                  <TableCell>{formatCurrency(value)}</TableCell>
                  <TableCell>{formatCurrency(balance)}</TableCell>
                  <TableCell>
                    <IconButton
                      component={LoadingButton}
                      size='small'
                      title={t('tables.actions.download')}
                      disabled={erpId === undefined}
                      loading={isLoading[erpId]}
                      onClick={() =>
                        erpId !== undefined &&
                        getInvoice(erpId, docType, docTypeErp, reference)
                      }
                    >
                      <GrDocumentPdf size={16} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReceiptsTable;
