import {
  Box,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft } from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';
import { formatCurrency } from '../../../constants/utils';
import { MyAccountTitle } from '../../../routes/AccountPage';
import { Order } from '../../../types/order';
import { FONT_STYLE, PAGES_SLUGS } from '../../../variables';
import SummaryProductInfo from '../../Cart/SummaryTable/SummaryProductInfo';
import QuantityOrder from '../../Quantity/QuantityOrder';
import { Product } from '../../../types/product';

const Highlights = styled(Card)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(1, 1fr)',
  gap: theme.spacing(3),
  padding: theme.spacing(2.5),
  [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
    gridTemplateColumns: 'repeat(2, 1fr)'
  },
  [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
    gridTemplateColumns: 'repeat(3, 1fr)'
  }
}));

const ProductsTable = ({
                         products,
                         orderDetails,
                         t
                       }: {
  products: any[];
  orderDetails: {
    shipping: number;
    taxes: number;
    discounts: number;
    total: number;
  };
  t: TFunction;
}) => (
  <TableContainer component={Paper} sx={{ marginTop: 1.5 }}>
    <Table id="orderDetailTable">
      <TableHead>
        <TableRow>
          <TableCell>{t('tables.details')}</TableCell>
          <TableCell>{t('tables.quantity')}</TableCell>
          <TableCell>{t('tables.totalUnits')}</TableCell>
          <TableCell>{t('tables.total')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {products.map((product, index) => {
          let productTotalQuantity = 0;
          if (Array.isArray(product)) {
            let productQuantity = 0;
            for (const variant of product) {
              productQuantity += variant.quantity.unit;
              productTotalQuantity += variant.quantity.unit;
            }
            return (
              <TableRow
                key={`orderDetailTable-product-${product[0].reference}-${index}`}
              >
                <TableCell>
                  <SummaryProductInfo
                    reference={product[0].erp_reference}
                    eanUn={product[0].eanUn}
                    eanCx={product[0].eanCx}
                    slug={product[0].slug}
                    title={product[0].title}
                    brand={product[0].brand}
                    itemsPerBox={product[0].itemsPerBox}
                    params={product[0]?.params}
                    hideImage
                  />
                </TableCell>
                <TableCell><Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 0.25
                  }}
                >
                  {product.map((variant, vIndex) => (<QuantityOrder
                    key={`sizeVariant-${vIndex}`}
                    product={variant}
                    isProductVariant
                    variant="size"
                    label={
                      variant.params.find((a) => a.id === 'size')
                        ?.value || '-'
                    }
                  />))}

                </Box></TableCell>
                <TableCell>{productTotalQuantity}</TableCell>
                <TableCell>
                  {formatCurrency(product[0].price * productQuantity)}
                </TableCell>
              </TableRow>
            );
          } else {
            const productQuantity = product.quantity.unit || product.quantity.box;
            return (
              <TableRow
                key={`orderDetailTable-product-${product.reference}-${index}`}
              >
                <TableCell>
                  <SummaryProductInfo
                    reference={product.erp_reference}
                    eanUn={product.eanUn}
                    eanCx={product.eanCx}
                    slug={product.slug}
                    title={product.title}
                    brand={product.brand}
                    itemsPerBox={product.itemsPerBox}
                    params={product?.params}
                    hideImage
                  />
                </TableCell>
                <TableCell><Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 0.25
                  }}
                >
                  <QuantityOrder
                    key={`sizeVariant-${index}`}
                    product={product}
                    isProductVariant
                    variant="size"
                    label={
                      product.params.find((a) => a.id === 'size')
                        ?.value || '-'
                    }
                  />
                </Box></TableCell>
                <TableCell>{productQuantity}</TableCell>
                <TableCell>
                  {formatCurrency(product.price * productQuantity)}
                </TableCell>
              </TableRow>
            );
          }
        })}
        <TableRow
          key={`orderDetailTable-details`}
          sx={{
            '& .MuiTypography-root': {
              lineHeight: 2
            },
            '& .MuiTypography-root:last-child': {
              fontSize: '1.15rem',
              fontWeight: FONT_STYLE.bold
            }
          }}
        >
          <TableCell />
          <TableCell />
          <TableCell>
            <Typography>{t('tables.vat')}</Typography>
            <Typography>{t('tables.shipping')}</Typography>
            {!!orderDetails.discounts && (
              <Typography>{t('tables.discounts')}</Typography>
            )}
            <Typography>{t('tables.total')}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{formatCurrency(orderDetails.taxes)}</Typography>
            <Typography>
              {orderDetails.shipping !== -1
                ? formatCurrency(orderDetails.shipping)
                : t('app.onRequest')}
            </Typography>
            {!!orderDetails.discounts && (
              <Typography>{formatCurrency(orderDetails.discounts)}</Typography>
            )}
            <Typography>{formatCurrency(orderDetails.total)}</Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

const AccountPurchasesDetail = () => {
  const { t } = useTranslation();
  const {
    state: { order }
  }: { state: { order: Order } } = useLocation();

  const {
    reference,
    date,
    status,
    expeditionType,
    deliveryDate,
    shippingAddress,
    billingAddress,
    shippingPrice,
    items: products,
    taxes,
    totalDiscount,
    total
  } = order;

  const groupProducts = (products: Product[]): (Product | Product[])[] => {
    const grouped: (Product | Product[])[] = [];
    const map: { [key: string]: { [key: string]: Product[] } } = {};

    // Agrupa produtos por erp_reference e color_id
    products.forEach((product) => {
      if (!map[product.erp_reference]) {
        map[product.erp_reference] = {};
      }

      if (!map[product.erp_reference][product.color_id]) {
        map[product.erp_reference][product.color_id] = [];
      }

      map[product.erp_reference][product.color_id].push(product);
    });

    // Prepara o array final
    for (const erpReference in map) {
      for (const colorId in map[erpReference]) {
        const productsWithSameColor = map[erpReference][colorId];
        if (productsWithSameColor.length > 1) {
          grouped.push(productsWithSameColor); // Adiciona o grupo se houver mais de um produto
        } else {
          grouped.push(productsWithSameColor[0]); // Adiciona o único produto diretamente
        }
      }
    }

    return grouped;
  };
  const totalTaxes = taxes.reduce((acc, tax) => acc + tax.total, 0);
  const hightlightedInformations: { title: string; value: string | number }[] =
    [
      {
        title: t('myAccount.orders.detail.orderNumber'),
        value: reference
      },
      {
        title: t('myAccount.orders.detail.orderDate'),
        value: date
      },
      {
        title: t('myAccount.orders.detail.orderStatus'),
        value: status
      },
      {
        title: t('myAccount.orders.detail.orderShipping'),
        value: expeditionType
      },
      {
        title: t('myAccount.orders.detail.orderDeliveryDate'),
        value: deliveryDate
      },
      {
        title: t('myAccount.orders.detail.orderPayment'),
        value: 'Transferência Multibanco'
      }
    ];

  return (
    <Grid container rowSpacing={4}>
      <Grid xs={12}>
        <MyAccountTitle>{t('myAccount.orders.detail.title')}</MyAccountTitle>
        <Typography variant="small">
          {t('myAccount.orders.detail.text')}
        </Typography>
        <Typography
          component={Link}
          to={`${PAGES_SLUGS.account}/${PAGES_SLUGS.myAccount.orders}`}
          title={t('checkout.goBackButton')}
          variant="link"
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            gap: 0.5,
            fontSize: '0.9rem',
            fontWeight: FONT_STYLE.semibold,
            marginTop: 2
          }}
        >
          <FiChevronLeft size={16} /> {t('checkout.goBackButton')}
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Highlights variant="outlined">
          {hightlightedInformations.map(({ title, value }, index) => (
            <Box key={`highlightedInformation-${index}`}>
              <Typography
                sx={{
                  fontWeight: FONT_STYLE.bold,
                  marginBottom: 0.5
                }}
              >
                {title}
              </Typography>
              <Typography>{value}</Typography>
            </Box>
          ))}
        </Highlights>
      </Grid>
      <Grid xs={12}>
        <MyAccountTitle>{t('myAccount.orders.detail.products')}</MyAccountTitle>
        <ProductsTable
          products={groupProducts(products)}
          orderDetails={{
            shipping: shippingPrice,
            taxes: totalTaxes,
            discounts: totalDiscount,
            total: total
          }}
          t={t}
        />

      </Grid>
      <Grid xs={6}>
        <MyAccountTitle>
          {t('myAccount.orders.detail.shippingAddress')}
        </MyAccountTitle>
        <Typography>
          {shippingAddress.street} <br /> {shippingAddress.zip_code},{' '}
          {shippingAddress.city}
          <br />
          {shippingAddress.country}
        </Typography>
      </Grid>
      <Grid xs={6}>
        <MyAccountTitle>
          {t('myAccount.orders.detail.billingAddress')}
        </MyAccountTitle>
        <Typography>
          {billingAddress.street} <br /> {billingAddress.zip_code},{' '}
          {billingAddress.city}
          <br />
          {billingAddress.country}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AccountPurchasesDetail;
